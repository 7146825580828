const htmlParser = () => {
  const replaceHtml = (key, value, element) => {
    const htmlToReplace = '{:' + key + '}';

    if (element.outerHTML.includes(htmlToReplace)) {
      var regExp = new RegExp(htmlToReplace, 'g');
      element.outerHTML = element.outerHTML.replace(regExp, value);
    }
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryStringElements = document.body.querySelectorAll('*');

  urlSearchParams.forEach(function (value, key) {
    queryStringElements.forEach(function (element) {
      replaceHtml(key, value, element);
    });
  });
};

export default htmlParser;
